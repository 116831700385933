<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>
        <div class="col-md-10 col-lg-10 col-sm-12">
          <template>
            <div>
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50 col-md-2.5"
                @click="$router.go(-1)"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t("returnToList") }}
              </b-button>
              <b-row class="match-height">
                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t("details") }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("orderNumber") }} :
                            </template>

                            <p class="mt-50 font-weight-bolder">
                              {{ items.itemnumber_text }}
                            </p>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-payTaobao"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("payTaobao") }} :
                            </template>

                            <p class="mt-50 font-weight-bolder">
                              <router-link
                                v-if="items.tao_id"
                                :to="{
                                  name: 'payfor-edit',
                                  params: { id: items.tao_id },
                                }"
                              >
                                {{ items.tao_itemnumber_text }}
                              </router-link>
                              <span v-else>-</span>
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("mnemonic") }} :
                            </template>
                            <div>
                              <b-form-textarea
                                id="textarea-default"
                                v-model="items.keyword"
                                rows="2"
                                @change="Updatekey()"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t("relatedAmountList") }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <div class="table-container">
                            <b-table
                              class="table-mobile w-100"
                              :fields="fieldsRelevant"
                              :items="Transactions"
                              show-empty
                            >
                              <template #empty="">
                                <b-table-empty-slot>
                                  <div class="text-center my-2">
                                    <img
                                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                                      alt="empty"
                                      height="60px"
                                    />
                                    <p class="text-muted">No Data</p>
                                  </div>
                                </b-table-empty-slot>
                              </template>

                              <template #cell(created_at)="data">
                                {{ time(data.item.created_at) }}
                              </template>

                              <template #cell(amount)="data">
                                <span :style="getAmountStyle(data.item.type)"
                                  >{{ getAmountPrefix(data.item.type) }}
                                  {{ Commas(data.item.amount) }} ฿</span
                                >
                              </template>
                            </b-table>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t("key-60") }}
                      </h3>
                    </div>

                    <div class="pl-2 pr-2">
                      <div class="table-container">
                        <b-table
                          class="table-mobile w-100"
                          :fields="fieldsRefund"
                          :items="items.products"
                          show-empty
                        >
                          <template #empty="">
                            <b-table-empty-slot>
                              <div class="text-center my-2">
                                <img
                                  src="@/assets/images/anan-img/svg/doc_empty.svg"
                                  alt="empty"
                                  height="60px"
                                />
                                <p class="text-muted">No Data</p>
                              </div>
                            </b-table-empty-slot>
                          </template>

                          <template #cell(number)="data">
                            {{ data.index + 1 }}
                          </template>
                          <template #cell(detail)="data">
                            {{ data.item.order_id }}
                          </template>
                          <template #cell(refund_time)="data">
                            {{ data.item.date_approve }}
                          </template>
                          <template #cell(img_refund)="data">
                            <div
                              v-if="data.item.file_refer"
                              class="preview-image-container mr-25 d-flex justify align-items-center"
                            >
                              <img
                                :src="
                                  GetImg(
                                    'Shop_refund_file',
                                    data.item.file_refer
                                  )
                                "
                                alt="Preview"
                                width="100px"
                                height="50px"
                                @click="showPicture(data.item.file_refer)"
                              />
                            </div>
                            <!-- {{ data.item.date_approve }} -->
                          </template>
                          <template #cell(amount)="data">
                            <span> ¥ {{ data.item.price }}</span>
                          </template>
                          <template #cell(action)="data">
                            <span
                              v-if="data.item.status == 1"
                              class="badge badge-light-primary"
                              >{{ $t("pendingReview") }}</span
                            >

                            <span
                              v-if="data.item.status == 2"
                              class="badge badge-light-success"
                              >{{ $t("operationCompleted") }}</span
                            >
                            <span
                              v-if="data.item.status == 0"
                              class="badge badge-light-secondary text-black"
                              style="font-size: 12px"
                              >{{ $t("cancelled") }}</span
                            >
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="pl-2 pr-5 p-2">
                      <div class="d-flex justify-content-end">
                        <p class="font-weight-bold h4">
                          {{ $t("totalRefund") }} :
                          {{
                            items.products.reduce(
                              (total, acc) =>
                                total + (acc.status == 2 ? acc.price : 0),
                              0
                            )
                          }}
                          ¥
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </div>
      </div>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>
<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormTextarea,
  BRow,
  BTable,
} from "bootstrap-vue";
import moment from "moment-timezone";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";
import SideBar from "../component/SideBar.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    SideBar,
    // ThisHeader,
    BFormTextarea,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      Transaction: [],
      ID: this.$route.params.id,
      items: [],
      Transations: [],
      Transactions: [],
    };
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: "created_at",
          label: this.$t("transactionDate"),
          thStyle: { width: "20%" },
          formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
        },
        {
          key: "detail",
          label: this.$t("details"),
          thStyle: { width: "30%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: this.$t("quantity"),
          thStyle: { width: "20%" },
          formatter: (value) => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ];
    },
    fieldsRefund() {
      return [
        {
          key: "number",
          label: this.$t("sequence"),
          thStyle: { width: "10%" },
        },
        {
          key: "detail",
          label: this.$t("orderList"),
          thStyle: { width: "30%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "refund_time",
          label: this.$t("key-61"),
          thStyle: { width: "20%" },
          formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
        },
        {
          key: "img_refund",
          label: this.$t("key-62"),
          thStyle: { width: "20%" },
          formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
        },
        {
          key: "amount",
          label: this.$t("price"),
          thStyle: { width: "10%" },
        },
        {
          key: "action",
          label: this.$t("manage"),
          thStyle: { width: "10%" },
        },
      ];
    },
  },
  mounted() {
    this.getData();
    this.getTransactions();
  },
  methods: {
    time(data) {
      return moment(data).format("YYYY-MM-DD HH:mm:ss");
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        };
        const { data: res } = await this.$http.get(
          "/Payforchina/getData/RefundOne",
          { params }
        );
        this.items = res;
        // console.log(res)
      } catch (err) {
        console.log(err);
      }
    },
    async getTransactions() {
      try {
        const params = {
          ref_id: this.ID,
        };
        const { data: res } = await this.$http.get("/transactions/show/ref", {
          params,
        });

        if (res.success) {
          this.Transactions = res.data;
          // this.getTransactionsChina(res.data)
        }
      } catch (err) {
        console.log(err);
        this.SwalError(err);
      }
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    Updatekey() {
      const obj = {
        id: this.ID,
        keyword: this.items.keyword,
      };
      this.$http
        .post("/Payforchina/Update/keyword", obj)
        .then(() => {
          this.getData();
          this.Success(this.$t("key-260"));
        })
        .catch((err) => {
          console.log(err);
          this.SwalError(err);
        });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(amount)) {
          return { color: "red" };
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: "green" };
        }
      }
      return { color: "orange" };
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(value)) {
          return "-";
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return "+";
        }
      }
      return "";
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: this.GetImg("Shop_refund_file", picture) };
      this.images.push(img);
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {
  .table-mobile {
    width: 800px !important;
  }
}
</style>
